<template>
  <div
    :class="['k2-checkbox', { 'k2-checkbox-disabled': isDisabled }]"
    v-bind="$attrs"
  >
    <v-checkbox
      v-model="isChecked"
      :class="classes"
      :indeterminate="isIndeterminate"
      :ripple="false"
      :color="getThemeColor(color) || undefined"
      :disabled="isDisabled"
      :label="label"
      :tabindex="tabIndex"
      :hide-details="true"
    >
      <template #append>
        <span :class="appendClasses" />
      </template>
      <template
        v-if="$slots.default"
        #label
      >
        <!-- @slot The content to display as the label for the checkbox -->
        <slot />
      </template>
    </v-checkbox>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

import { CheckboxValue, CheckboxSize, CheckboxStates, VueClass } from 'komodo2/types.ts'
import type { CheckboxState } from 'komodo2/types.ts'
import { useTheme } from 'komodo2/composables/useTheme.ts'

export default {
  name: 'KomodoCheckbox'
}
</script>

<script setup lang="ts">
interface Props {
  /**
   * The size variant to render
   * @values default, small
   */
  size?: CheckboxSize
  /**
   * The component state to render
   * @values default, error, disabled, lightened
   */
  state?: CheckboxState
  /**
   * The content to display as the label for the checkbox (if the slot is not being used)
   */
  label?: string
  /**
   * Indicates where to place component when using tab navigation
   */
  tabIndex?: number
  /**
   * Apply given color to checkbox in filled state
   */
  color?: string
}

const props = withDefaults(defineProps<Props>(), {
  size: CheckboxSize.Default,
  state: CheckboxStates.Default,
  label: '',
  tabIndex: 0,
  color: 'main_tegus_blue'
})

/**
 * The checkbox value
 */
const isChecked = defineModel<CheckboxValue>({ default: false })

const { getThemeColor } = useTheme()

const appendClasses = computed((): VueClass => {
  return {
    'checkbox-circle': true,
    'circle-inactive': !isDisabled.value && isChecked.value === false,
    'circle-active': !isDisabled.value && isChecked.value !== false
  }
})

const classes = computed((): VueClass => {
  return {
    'checkbox-box': true,
    'checkbox-indeterminate': isIndeterminate.value,
    'checkbox-box--small': props.size === CheckboxSize.Small,
    'checkbox-box--default': props.size === CheckboxSize.Default,
    'checkbox-box--lightened': props.state === CheckboxStates.Lightened
  }
})

const isDisabled = computed((): boolean => {
  return props.state === CheckboxStates.Disabled
})

const isIndeterminate = computed((): boolean => {
  return isChecked.value === null
})
</script>

<style lang="scss" scoped>
.k2-checkbox {
  display: flex;
  position: relative;
  align-items: center;
  left: -6px;
  min-height: 24px;
  min-width: 24px;

  :deep(.v-input--density-default) {
    --v-input-control-height: 24px;
  }

  :deep(.v-selection-control--density-default) {
    --v-selection-control-size: 24px;

    .v-selection-control__input:hover::before {
      opacity: 0;
    }
  }

  :deep(.v-selection-control__wrapper) {
    height: 24px;
    width: 24px;
  }

  .circle-inactive {
    :deep(.v-selection-control__input) {
      .v-icon {
        @include k2-color-text-tertiary-text;

        opacity: 1;
      }
    }
  }

  .circle-active {
    .v-icon {
      @include k2-color-main-tegus-blue;

      opacity: 1;
    }
  }

  &:hover .circle-inactive {
    @include k2-background-secondary-lighten-4;
  }

  &:active .circle-inactive {
    @include k2-background-secondary-lighten-3;
  }

  &:hover .circle-active {
    @include k2-background-primary-lighten-5;
  }

  &:active .circle-active {
    @include k2-background-primary-lighten-4;
  }
}

:deep(.v-label) {
  @include k2-color-text-primary-text;
  @include k2-font-text-text;

  opacity: 1;
  padding-left: 4px;
}

:deep(.v-input__control) {
  z-index: 2;
}

:deep(.v-input--horizontal .v-input__append) {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0;
  margin-inline-start: 0;
  opacity: .5;
  z-index: 1;
}

.v-input.checkbox-box {
  &.checkbox-indeterminate {
    :deep(.v-icon) {
      @include k2-color-main-tegus-blue;
    }
  }

  :deep(.v-input__slot) {
    margin-bottom: 0px;
  }

  :deep(.v-selection-control__input) {
    margin-right: 0px;
  }

  &--small {
    padding: 0;

    :deep(.v-selection-control__input) {
      transform: scale(0.5)
    }

    .checkbox-circle {
      border-radius: 18px;
      display: inline-block;
      height: 24px;
      width: 24px;
    }

    :deep(.v-label) {
      @include k2-color-text-primary-text;
      @include k2-font-text-text-small-reg;

      padding-left: 1px;
    }
  }

  &--default {
    :deep(.v-selection-control__input) {
      transform: scale(0.67)
    }

    .checkbox-circle {
      border-radius: 18px;
      display: inline-block;
      height: 24px;
      width: 24px;
    }

    :deep(.v-label) {
      @include k2-color-text-primary-text;
      @include k2-font-text-text;

      padding-left: 4px;
    }
  }

  &--lightened {
    :deep(.v-icon) {
      @include k2-color-stroke-separator;
    }
  }
}

.k2-checkbox.k2-checkbox-disabled {
  :deep(.v-label) {
    @include k2-color-surface-disabled-bg;
  }

  .v-input--checkbox.checkbox-box {
    :deep(.v-icon) {
      @include k2-color-surface-disabled-bg;
    }
  }
}
</style>
